import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import SliderRange from '@redoute/slider-range';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SliderRangeEnhanced = makeShortcode("SliderRangeEnhanced");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sliderrange"
    }}>{`SliderRange`}</h1>
    <p>{`The SliderRange is a styled component`}</p>
    <Playground __position={0} __code={'() => {\n  const SliderRangeEnhanced = props => {\n    const [value, setValue] = useState(0.5)\n    return <SliderRange {...props} value={value} onChange={setValue} />\n  }\n  return <SliderRangeEnhanced min={0} max={1} step={0.05} />\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      SliderRange,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const SliderRangeEnhanced = props => {
          const [value, setValue] = useState(0.5);
          return <SliderRange {...props} value={value} onChange={setValue} mdxType="SliderRange" />;
        };

        return <SliderRangeEnhanced min={0} max={1} step={0.05} mdxType="SliderRangeEnhanced" />;
      }}
    
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={SliderRange} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      